import MenuIcon from "@mui/icons-material/Menu";
import { IconButton } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import React from "react";
import AnalysisReport from "../assets/AnalysisRequirementReport.pdf";
import DetailedDesignReport from "../assets/DetailedDesignReport.pdf";
import FinalReport from "../assets/FinalReport.pdf";
import UserManual from "../assets/ParkrUserManual.pdf";
import SpecReport from "../assets/SpecifictionReport.pdf";
import FinalPresentation from "../assets/FinalPresentation.pdf";
import logo from "../assets/logo.png";
import { darkgrey, white } from "../constants/colors";
import "./Navbar.css";

const Navbar = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const menuItems = [
    {
      title: "Specification Report",
      file: SpecReport,
    },
    {
      title: "Analysis and Requirement Report",
      file: AnalysisReport,
    },
    {
      title: "Detailed Design Report",
      file: DetailedDesignReport,
    },
    {
      title: "Final Report",
      file: FinalReport,
    },
    {
      title: "Final Presentation",
      file: FinalPresentation,
    },
    {
      title: "Demo Video",
      file: "https://youtu.be/ky2wOU-T7_o",
    },
    {
      title: "User Manual",
      file: UserManual,
    },
  ];

  return (
    <AppBar
      position="fixed"
      sx={{
        bgcolor: "transparent",
        boxShadow: "0 0px 0px rgba(0, 0, 0, 0)",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box
            variant="h6"
            noWrap
            component="div"
            sx={{
              height: 30,
              mr: 2,
              display: { xs: "none", md: "flex" },
              alignItems: "center",
            }}
          >
            <img src={logo} alt="logo" id="header-logo" />
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {menuItems.map((item, index) => (
                <a
                  href={item.file}
                  target="_blank"
                  download
                  style={{ textDecoration: "none" }}
                >
                  <MenuItem key={index} onClick={item.onClick}>
                    <Typography textAlign="center" sx={{ color: darkgrey }}>
                      {item.title}
                    </Typography>
                  </MenuItem>
                </a>
              ))}
            </Menu>
          </Box>
          <Box
            variant="h6"
            noWrap
            component="div"
            sx={{
              height: 70,
              flexGrow: 1,
              display: { xs: "flex", md: "none" },
            }}
          >
            <img src={logo} alt="logo" id="header-logo" />
            <IconButton
              aria-label="delete"
              size="small"
              onClick={handleOpenNavMenu}
            >
              <MenuIcon fontSize="inherit" sx={{ color: white }} />
            </IconButton>
          </Box>
          <Grid
            container
            justifyContent="flex-end"
            sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}
          >
            {menuItems.map((item, index) => (
              <a
                href={item.file}
                target="_blank"
                download
                style={{ textDecoration: "none" }}
              >
                <Button
                  key={index}
                  onClick={item.onClick}
                  sx={{
                    my: 2,
                    color: "#EDFFAE",
                    textTransform: "initial",
                    fontSize: "medium",
                    mr: "24px",
                  }}
                  startIcon={item.icon}
                >
                  {item.title}
                </Button>
              </a>
            ))}
          </Grid>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default Navbar;
