import "./App.css";
import Navbar from "./components/Navbar";
import { Toolbar, Box, CssBaseline, Grid, Typography, Button } from "@mui/material";
import { darkgrey, lime, lime60, white } from "./constants/colors";
import landing from "./assets/landing.png";
import GitHubIcon from '@mui/icons-material/GitHub';

function App() {
  return (
    <Box
      sx={{
        display: "flex",
        height: "100vh",
        width: "100wh",
        backgroundColor: darkgrey,
      }}
    >
      <CssBaseline />
      <Navbar />
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: "100vw", height: "100vh" }}
      >
        <Toolbar />

        <Grid container spacing={2} sx={{ height: "100vh", display: { xs: 'none', md: 'flex' } }}>
          <Grid item xs={6} sx={{ width: "100%", height: "100%", }}>
            <Typography
              variant="h4"
              gutterBottom
              sx={{ color: lime, marginTop: "15%", marginLeft: "5%" }}
            >
              Project Summary:
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              sx={{ color: white, marginTop: "2%", marginLeft: "5%" }}
            >
              Parkr is a mobile app attempting to solve parking issue worldwide.
              It displays real-time capacity and availability of parking lots
              with the help of specialized hardware capturing any car entering
              or exiting the lot. It also has a map view showing all nearby
              parking lots with the availability and pricing information. User
              can choose a specific parking lot from the map and can see the
              direct route from his location to the parking lot, and he/she also
              can reserve his/her spot via online payment for a specific date
              and time. Clients (parkings lots) can subscribe to Parkr using
              app's dashboard, and after some necessary setups, the parking lot
              is added to the system. Parkr also displays important data for
              each district and city such as average parking pricing, frequency
              of parking lots, and so on.
            </Typography>
            <Grid container spacing={0} sx={{ width:"100%", marginLeft:"5%", marginTop:2 }}  >
              <Grid md={6} sx={{display:"flex" , flexDirection:"row", alignItems:"center" }} >
                <Button href="https://github.com/sebnemturkoglu/Parkr-Frontend" >
                <GitHubIcon sx={{ color:lime60 }} />
                <Typography sx={{marginLeft:1, color:lime60, textTransform:"none"}}>Frontend Github Repo</Typography>
                </Button>
              </Grid>
              <Grid  md={6} sx={{ display:"flex" , flexDirection:"row", alignItems:"center" }} >
                <Button href="https://github.com/mertsenn1/parkr">
                <GitHubIcon sx={{ color:lime60 }} />
                <Typography sx={{marginLeft:1, color:lime60, textTransform:"none"}}>Backend Github Repo</Typography>
                </Button>
              </Grid>
            </Grid>
            <Typography
              variant="h4"
              gutterBottom
              sx={{ color: lime, marginTop: "5%", marginLeft: "5%" }}
            >
              Group Members:
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              sx={{ color: white, marginTop: "2%", marginLeft: "5%" }}
            >
              Ege Demirkırkan
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              sx={{ color: white, marginLeft: "5%" }}
            >
              Arda Atahan İbiş
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              sx={{ color: white, marginLeft: "5%" }}
            >
              Arman Engin Sucu
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              sx={{ color: white, marginLeft: "5%" }}
            >
              Mert Şen
            </Typography>

            <Typography
              variant="body1"
              gutterBottom
              sx={{ color: white, marginLeft: "5%" }}
            >
              Şebnem Türkoğlu
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ padding: "10%", objectFit: "contain" }}
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
          >
            <img
              src={landing}
              alt="logo"
              id="header-logo"
              style={{
                objectFit: "contain",
                aspectRatio: "auto",
                maxWidth: "90%",
                right: 0,
              }}
            />
          </Grid>
        </Grid>
        <Grid  sx={{ width:"100vw", height: "100vh", display: { xs: 'flex-column', md: 'none' } }}>
            <Typography
              variant="h4"
              gutterBottom
              sx={{ color: lime, marginTop: "15%" }}
            >
              Project Summary:
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              sx={{ color: white, marginTop: "2%"}}
            >
              Parkr is a mobile app attempting to solve parking issue worldwide.
              It displays real-time capacity and availability of parking lots
              with the help of specialized hardware capturing any car entering
              or exiting the lot. It also has a map view showing all nearby
              parking lots with the availability and pricing information. User
              can choose a specific parking lot from the map and can see the
              direct route from his location to the parking lot, and he/she also
              can reserve his/her spot via online payment for a specific date
              and time. Clients (parkings lots) can subscribe to Parkr using
              app's dashboard, and after some necessary setups, the parking lot
              is added to the system. Parkr also displays important data for
              each district and city such as average parking pricing, frequency
              of parking lots, and so on.
            </Typography>
            <Grid container spacing={0} sx={{ width:"100%", marginTop:2 }}  >
              <Grid xs={12} sm={6} sx={{display:"flex" , flexDirection:"row", alignItems:"center" }} >
                <Button >
                <GitHubIcon sx={{ color:lime60 }} />
                <Typography sx={{marginLeft:1, color:lime60, textTransform:"none"}}>Frontend Github Repo</Typography>
                </Button>
              </Grid>
              <Grid  xs={12} sm={6} sx={{ display:"flex" , flexDirection:"row", alignItems:"center" }} >
                <Button >
                <GitHubIcon sx={{ color:lime60 }} />
                <Typography sx={{marginLeft:1, color:lime60, textTransform:"none"}}>Backend Github Repo</Typography>
                </Button>
              </Grid>
            </Grid>
            <Typography
              variant="h4"
              gutterBottom
              sx={{ color: lime, marginTop: "5%", width:"100vw" }}
            >
              Group Members:
            </Typography>
            <Typography
              variant="body1"
              sx={{ color: white, marginTop: "2%", }}
            >
              Ege Demirkırkan
            </Typography>
            <Typography
              variant="body1"
              sx={{ color: white,}}
            >
              Arda Atahan İbiş
            </Typography>
            <Typography
              variant="body1"
              sx={{ color: white, }}
            >
              Arman Engin Sucu
            </Typography>
            <Typography
              variant="body1"
              sx={{ color: white, }}
            >
              Mert Şen
            </Typography>

            <Typography
              variant="body1"
              sx={{ color: white, }}
            >
              Şebnem Türkoğlu
            </Typography>

        </Grid>
      </Box>
    </Box>
  );
}

export default App;
